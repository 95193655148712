@import "../../styles/index.scss";

.column_title_container {
  .column_title {
    position: relative;
  }
}

.footer_sec_links {
  list-style-type: none;
  padding-inline-start: unset;
  margin: 0;
  .footer_sec_link {
    color: $fuLightGrayColor;
    transition: all 0.3s;
    text-decoration: none;
    font-weight: 400;
    @extend .line-clamp-1;
    &:hover {
      color: $fuYellowColor;
    }
  }
}

.social_box {
  & {
    display: flex;
  }

  .social_Icons_container,
  .streaming_Icons_container {
    display: flex;
    align-items: center;
  }

  .social_icon_container {
    & {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $fuTransparentColor;
      margin: 0 5px;
      transition: all 0.3s;
    }
    .social_icon {
      fill: $fuLightGrayColor;
      transition: all 0.3s;
    }
    &:hover {
      .social_icon {
        fill: $fuYellowColor;
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 310px) {
  .social_box {
    & {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .social_icon_container {
      & {
        width: 25px;
        height: 25px;
      }
    }
    .social_Icons_container {
      margin-bottom: 5px;
    }
  }
}
@media screen and (min-width: 310px) and (max-width: 480px) {
  .social_box {
    & {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .social_Icons_container {
      margin-bottom: 5px;
    }
  }
}
@media screen and (min-width: 0px) and (max-width: 768px) {
  .column_title_container {
    .column_title {
      font-weight: 400;
    }
  }
  .footer_sec_links {
    .footer_sec_link {
      display: flex;
      width: 100%;
      padding: 4px 0;
    }
    & > :first-child .footer_sec_link {
      padding-top: 0;
    }
    & > :last-child .footer_sec_link {
      padding-bottom: 0;
    }
  }
}
