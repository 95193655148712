@import "../../styles/index.scss";

.partner_container {
  position: relative;
  .partnership_icon_container {
    .partnership_icon {
      width: 300px;
      height: 300px;
      margin: 0 auto;
      padding: 15px;
      border-radius: 4px;
    }
  }
  .partnership_description {
    .partner_name {
      font-size: 20px;
      font-weight: 700;
      color: $fuYellowColor;
    }
    .partner_bio {
      color: $fuLightGrayColor;
      font-size: 16px;
    }
    .social_box {
      & {
        display: flex;
      }
      .social_icon_container {
        & {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 5px;
          transition: all 0.3s;
        }
        .social_icon {
          fill: $fuLightGrayColor;
          transition: all 0.3s;
        }
        &:hover {
          .social_icon {
            fill: $fuYellowColor;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 310px) {
  .partner_container {
    .partnership_icon_container {
      .partnership_icon {
        width: 240px;
        height: 240px;
      }
    }
    .partnership_description {
      .partner_name {
        font-size: 16px;
      }
      .partner_bio {
        font-size: 14px;
      }
      .social_box {
        & {
          justify-content: center;
        }
      }
    }
  }
  .partner_separator {
    height: 2px;
    border-radius: 20px;
    background-color: $fuMidGrayColor;
    margin: 15px 0;
  }
}
@media screen and (min-width: 310px) and (max-width: 400px) {
  .partner_container {
    .partnership_icon_container {
      .partnership_icon {
        width: 250px;
        height: 250px;
      }
    }
    .partnership_description {
      .partner_name {
        font-size: 16px;
      }
      .partner_bio {
        font-size: 14px;
      }
      .social_box {
        & {
          justify-content: center;
        }
      }
    }
  }
  .partner_separator {
    height: 2px;
    border-radius: 20px;
    background-color: $fuMidGrayColor;
    margin: 15px 0;
  }
}
@media screen and (min-width: 400px) and (max-width: 480px) {
  .partner_container {
    .partnership_icon_container {
      .partnership_icon {
        width: 260px;
        height: 260px;
      }
    }
    .partnership_description {
      .partner_name {
        font-size: 18px;
      }
      .partner_bio {
        font-size: 15px;
      }
      .social_box {
        & {
          justify-content: center;
        }
      }
    }
  }
  .partner_separator {
    height: 2px;
    border-radius: 20px;
    background-color: $fuMidGrayColor;
    margin: 15px 0;
  }
}
@media screen and (min-width: 480px) and (max-width: 575.9px) {
  .partner_container {
    .partnership_icon_container {
      .partnership_icon {
        width: 270px;
        height: 270px;
      }
    }
    .partnership_description {
      .partner_name {
        font-size: 18px;
      }
      .partner_bio {
        font-size: 15px;
      }
      .social_box {
        & {
          justify-content: center;
        }
      }
    }
  }
  .partner_separator {
    height: 2px;
    border-radius: 20px;
    background-color: $fuMidGrayColor;
    margin: 15px 0;
  }
}
@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .partner_container {
    .partnership_icon_container {
      .partnership_icon {
        width: 270px;
        height: 270px;
      }
    }
    .partnership_description {
      .social_box {
        & {
          justify-content: center;
        }
      }
    }
  }
  .partner_separator {
    height: 2px;
    border-radius: 20px;
    background-color: $fuMidGrayColor;
    margin: 15px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .partner_container {
    .partnership_icon_container {
      .partnership_icon {
        width: 280px;
        height: 280px;
      }
    }
    .partnership_description {
      .social_box {
        & {
          justify-content: center;
        }
      }
    }
  }
  .partner_separator {
    height: 2px;
    border-radius: 20px;
    background-color: $fuMidGrayColor;
    margin: 15px 0;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .partner_container {
    .partnership_icon_container {
      .partnership_icon {
        width: 220px;
        height: 220px;
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .partner_container {
    .partnership_icon_container {
      .partnership_icon {
        width: 260px;
        height: 260px;
      }
    }
  }
}
