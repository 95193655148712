@import "../../styles/index.scss";

.search_info {
  color: $fuLightGrayColor;
  font-size: 20px;
}

.obj_btn_container {
  border-radius: 50px;
  overflow: hidden;
  .obj_link {
    text-decoration: none;
    display: inline-flex;
    width: 100%;
    border-radius: 50px;
    overflow: hidden;
  }
  .obj_btn {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid $fuLightGrayColor;
    font-size: 16px;
    padding: 5px 14px;
    border-radius: 50px;
    transition: all 0.3s;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0%;
      background-color: $fuTransparentColor;
      transition: all 0.3s;
    }
    .obj_name {
      display: block;
      color: $fuLightGrayColor;
      font-weight: 400;
      transition: all 0.3s;
    }
    .obj_count {
      display: block;
      color: $fuLightGrayColor;
      font-weight: 400;
      transition: all 0.3s;
    }
    &:hover {
      border-color: $fuYellowColor;
    }
  }
  .obj_btn_active {
    border: 1px solid $fuYellowColor;
    .obj_name {
      color: $fuDarkGrayColor;
      font-weight: 600;
    }
    .obj_count {
      color: $fuYellowColor;
      font-weight: 600;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 60%;
      transform: translateX(-10px);
      background-color: $fuYellowColor;
      z-index: -1;
      border-radius: 50px;
    }
  }
}

// VS
@media screen and (min-width: 0) and (max-width: 310px) {
  .search_info {
    font-size: 12px;
    font-weight: 400;
  }
  .obj_btn_container {
    .obj_btn {
      font-size: 12px;
      padding: 3px 10px;
    }
  }
}
// XXXS
@media screen and (min-width: 310px) and (max-width: 399.9px) {
  .search_info {
    font-size: 14px;
    font-weight: 400;
  }
  .obj_btn_container {
    .obj_btn {
      font-size: 14px;
      padding: 4px 12px;
    }
  }
}
// XXS
@media screen and (min-width: 400px) and (max-width: 479.9px) {
  .search_info {
    font-size: 14px;
    font-weight: 400;
  }
  .obj_btn_container {
    .obj_btn {
      font-size: 14px;
      padding: 4px 12px;
    }
  }
}
// XS
@media screen and (min-width: 480px) and (max-width: 575.9px) {
  .search_info {
    font-size: 15px;
  }
  .obj_btn_container {
    .obj_btn {
      font-size: 14px;
      padding: 4px 12px;
    }
  }
}
// SM
@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .search_info {
    font-size: 16px;
  }
  .obj_btn_container {
    .obj_btn {
      font-size: 14px;
      padding: 3px 10px;
    }
  }
}
// MD
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .search_info {
    font-size: 18px;
  }
  .obj_btn_container {
    .obj_btn {
      font-size: 14px;
      padding: 4px 12px;
    }
  }
}
// LG
@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .search_info {
    font-size: 18px;
  }
  .obj_btn_container {
    .obj_btn {
      font-size: 14px;
      padding: 4px 12px;
    }
  }
}
