@import "./colors";
@import "./mixins";

.bg-black-color {
  background-color: $fuBlackColor !important;
}

.bg-dark-gray {
  background-color: $fuDarkGrayColor !important;
}

input[type="text"] {
  background-color: $fuLightGrayColor !important;
  border-color: $fuLightGrayColor !important;
  transition: all 0.2s !important;
}

input[type="text"]:focus {
  background-color: $fuWhiteColor !important;
  border-color: $fuYellowColor !important;
}

input[type="checkbox"] {
  background-color: $fuLightGrayColor !important;
}

input[type="checkbox"]:checked {
  background-color: $fuYellowColor !important;
}

input[type="radio"] {
  background-color: $fuLightGrayColor !important;
}

input[type="radio"]:checked {
  background-color: $fuYellowColor !important;
}

// --------------- pagination

.pagination-circle {
  position: relative;
  height: 30px;
  width: 30px;
  background: $fuLightGrayColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 35%;
  border: 2px solid $fuTransparentColor;
  transition: all 0.2s;
}

.pagination-circle-inner {
  font-size: 14px;
  font-weight: 500;
  color: $fuWhiteColor;
}

.active-pagination-circle {
  background-color: $fuMidGrayColor;
  opacity: 100%;
  border: 2px solid $fuYellowColor !important;
  transition: all 0.2s;
  .pagination-circle-inner {
    color: $fuYellowColor;
  }
}

.pagination-next,
.pagination-prev {
  background-color: $fuTransparentColor;
  border: none;
}

// --------------- end pagination

.line-clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-word-break: break-word;
  word-break: break-word;
}

.line-clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-word-break: break-word;
  word-break: break-word;
}

.line-clamp-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -webkit-word-break: break-word;
  word-break: break-word;
}

.required-input {
  color: $fuAlertColor;
}

.optional-input {
  color: $fuLightGrayColor;
}

[data-title] {
  position: relative;
  z-index: 999999 !important;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  position: absolute;
  bottom: -1.6em;
  left: 100%;
  padding: 4px 8px;
  color: $fuYellowColor;
  white-space: nowrap;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-size: 12px;
  background-color: $fuMidGrayColor;
  opacity: 0;
  z-index: 999999 !important;
  visibility: hidden;
}

.textarea-container {
  border-radius: 0.375rem;
  border-bottom-right-radius: 0.55rem;
  overflow: hidden;
}

textarea::-webkit-resizer {
  background-color: inherit;
  border-bottom-right-radius: inherit;
  background-image: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='15' height='' fill='rgb(47, 47, 47)' class='bi bi-textarea-resize' viewBox='0 0 16 16'>  <path d='M0 4.5A2.5 2.5 0 0 1 2.5 2h11A2.5 2.5 0 0 1 16 4.5v7a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 0 11.5v-7zM2.5 3A1.5 1.5 0 0 0 1 4.5v7A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 13.5 3h-11zm10.854 4.646a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708l3-3a.5.5 0 0 1 .708 0zm0 2.5a.5.5 0 0 1 0 .708l-.5.5a.5.5 0 0 1-.708-.708l.5-.5a.5.5 0 0 1 .708 0z'/></svg>");
  background-position: 0;
  background-repeat: no-repeat;
}

textarea::-webkit-scrollbar-track {
  background-color: inherit;
}

textarea::-webkit-scrollbar-button:single-button:vertical:decrement {
  display: none;
}
textarea::-webkit-scrollbar-button:single-button:vertical:increment {
  display: none;
}

.item-search-box-container {
  & {
    position: relative;
  }
  .item-search-box-loading {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .item-search-box {
    width: 25rem;
  }
}

// common in components

.main-item-search-box {
  width: 25rem;
}

.table-heading-brdr {
  border-bottom: 1px solid $fuLightGrayColor;
}

.row-brdr {
  border-bottom: 1px solid $fuMidGrayColor;
}

.toast-msg-container-parent {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999 !important;
}

.toast-msg-container {
  width: max-content !important;
  transition: width 0.3s ease !important;
  z-index: 9999 !important;
}

.Toastify__toast {
  width: max-content !important;
  max-width: 380px !important;
  margin-left: auto !important;
  .Toastify__toast-body {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      -webkit-word-break: break-word;
      word-break: break-word;
    }
  }
}

.iu-row-heading {
  .row-title {
    position: relative;
    overflow: hidden;
    font-size: 20px;
    font-weight: 600;
    // letter-spacing: 2px;
    color: $fuWhiteColor;

    &::after {
      content: "";
      position: absolute;
      margin-left: 5px;
      top: 50%;
      transform: translateY(50%);
      width: 100%;
      height: 1px;
      background-color: $fuWhiteColor;
    }
  }
}

.info-tags-container {
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .info-tag {
    background-color: $fuMidGrayColor;
    color: $fuLightGrayColor;
    border-radius: 40px;
    padding: 2px 18px;
  }
}

.loading-fixed-dimensions {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-row-heading {
  .row-title {
    position: relative;
    overflow: hidden;
    font-size: 20px;
    font-weight: 600;
    color: $fuWhiteColor;
    &::after {
      content: "";
      position: absolute;
      margin-left: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: $fuWhiteColor;
    }
  }
}

.item-releases-search-row-height {
  min-height: 402.5px;
  transition: all 0.3s;
}

.item-releases-search-msg-height {
  min-height: 402.5px;
}

.item-news-search-row-height {
  min-height: 353.5px;
  transition: all 0.3s;
}

.item-news-search-msg-height {
  min-height: 353.5px;
}

.item-videos-search-row-height {
  min-height: 454px;
  transition: all 0.3s;
}

.item-videos-search-msg-height {
  min-height: 454px;
}

.item-artists-search-row-height {
  min-height: 277px;
  transition: all 0.3s;
}

.item-artists-search-msg-height {
  min-height: 277px;
}

.item-labels-search-row-height {
  min-height: 285px;
  transition: all 0.3s;
}

.item-labels-search-msg-height {
  min-height: 285px;
}

.item-role-users-search-row-height {
  min-height: 200px;
  transition: all 0.3s;
}

.item-role-users-search-msg-height {
  min-height: 200px;
}

.item-role-permissions-search-row-height {
  min-height: 200px;
  transition: all 0.3s;
}

.item-role-permissions-search-msg-height {
  min-height: 200px;
}

.item-genres-search-row-height {
  min-height: 175.6px;
  transition: all 0.3s;
}

.item-genres-search-msg-height {
  min-height: 175.6px;
}

.w-fit-content {
  width: fit-content !important;
}

.verification-circle {
  & {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: $fuDarkGrayColor;
    border-width: 0;
    border: 2px solid $fuYellowColor;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .futurising-icon {
    width: 12px;
    height: 12px;
  }
}

.top-five-circle,
.pre-order-circle {
  & {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: $fuDarkGrayColor;
    border-width: 0;
    border: 2px solid $fuYellowColor;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $fuYellowColor;
    font-size: 8px;
  }
  .top-five-order {
    font-size: 10px;
    font-weight: 700;
    line-height: 100%;
  }
}

.details-top-five-circle,
.details-pre-order-circle {
  & {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $fuDarkGrayColor;
    border-width: 0;
    border: 2px solid $fuYellowColor;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $fuYellowColor;
    font-size: 10px;
  }
  .top-five-order {
    font-size: 12px;
    font-weight: 700;
  }
}

.main-item-filter-box {
  .filter-dropdown-container {
    .filter-dropdown-btn {
      & {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $fuLightGrayColor;
        border: 1px solid $fuLightGrayColor;
        text-align: unset;
        transition: all 0.3s !important;
        color: $fuDarkGrayColor;
      }
      &::after {
        content: "";
        border: none;
      }
    }
    .filter-dropdown-btn[aria-expanded="true"] {
      & {
        background-color: $fuWhiteColor;
        border-color: $fuYellowColor;
      }
    }
    .filter-dropdown {
      height: 20rem;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar-track {
        background-color: inherit;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
      }

      &::-webkit-scrollbar-button:single-button:vertical:decrement {
        display: none;
      }
      &::-webkit-scrollbar-button:single-button:vertical:increment {
        display: none;
      }

      .filter-dropdown-search-input {
        background-color: transparent !important;
        border: none !important;
      }
    }
  }
}

.item-li-dropdown {
  background-color: $fuTransparentColor !important;
  color: $fuDarkGrayColor !important;
}

.height-fit-content {
  height: fit-content !important;
}

.select-options {
  input {
    background-color: $fuTransparentColor !important;
  }
  input:focus {
    background-color: $fuTransparentColor !important;
  }
}

.select-filtration {
  background-color: $fuLightGrayColor !important;
  border: 1px solid $fuLightGrayColor !important;
  box-shadow: none !important;
  transition: all 0.3s !important;
  &:hover {
    border: 1px solid $fuLightGrayColor !important;
  }
  display: flex !important;
  flex-wrap: nowrap !important;
  // overflow-x: scroll;
}

.css-3w2yfm-ValueContainer {
  display: flex !important;
  flex-wrap: nowrap !important;
  overflow-x: scroll !important;

  &::-webkit-scrollbar {
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    height: 0 !important;
  }

  &::-webkit-scrollbar-thumb {
    height: 0 !important;
  }

  &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    display: none !important;
  }

  &::-webkit-scrollbar-button:single-button:horizontal:increment {
    display: none !important;
  }

  div {
    overflow: visible !important;
    width: max-content !important;
  }
}

input::placeholder {
  color: $fuMidGrayColor !important;
}

textarea::placeholder {
  color: $fuMidGrayColor !important;
}

.css-1jqq78o-placeholder {
  display: block !important;
  flex-grow: 1 !important;
  color: $fuMidGrayColor !important;
  position: absolute;
}

.css-1nmdiq5-menu {
  overflow: hidden !important;
  z-index: 99 !important;
  div {
    div {
      padding: 5px 10px !important;
      background-color: $fuWhiteColor;
      color: $fuDarkGrayColor !important;
      transition: all 0.1s;
      &:hover {
        background-color: rgba($color: $fuYellowColor, $alpha: 0.5) !important;
      }
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
      -webkit-word-break: break-word !important;
      word-break: break-word !important;
    }
  }
  div {
    &::-webkit-scrollbar-track {
      background-color: inherit;
    }

    &::-webkit-scrollbar-button:single-button:vertical:decrement {
      display: none;
    }
    &::-webkit-scrollbar-button:single-button:vertical:increment {
      display: none;
    }
  }
}

.css-qbdosj-Input {
  display: inline-flex !important;
}

.select-filtration:has(input[aria-expanded="true"]) {
  border-color: $fuYellowColor !important;
  background-color: $fuWhiteColor !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: $fuDarkGrayColor !important;
}

.css-tj5bde-Svg {
  fill: $fuDarkGrayColor !important;
  transition: all 0.3s;
  cursor: pointer !important;
  &:hover {
    fill: $fuYellowColor !important;
  }
}

.css-184em7j-MultiValueRemove {
  svg {
    fill: $fuLightGrayColor !important;
  }
}

.form-explain {
  font-size: 12px;
  font-weight: 400;
}

.text-lined-through {
  text-decoration: line-through;
}

.z-index-high {
  z-index: 100;
}

.custom-height {
  min-height: calc(100vh - 24px);
}

.online-circle {
  background-color: $fuGreenColor;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.offline-circle {
  background-color: $fuLightGrayColor;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.swiper {
  .swiper-slide {
    height: auto;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear, visibility 0.2s, color 0.2s linear, background-color 0.2s linear, border-color 0.2s linear;
    width: 40px;
    height: 40px;
    background-color: rgba($color: $fuLightBlackColor, $alpha: 0.5);
    border-radius: 50%;
    border: 2px solid $fuLightGrayColor;
    color: $fuLightGrayColor;
    font-weight: 800;

    &::after {
      font-size: 14px !important;
      top: 50%;
      left: 50%;
    }
    &:hover {
      opacity: 1 !important;
      border-color: $fuYellowColor;
      color: $fuYellowColor;
      background-color: rgba($color: $fuLightBlackColor, $alpha: 0.8);
    }
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0.5;
      visibility: visible;
    }
    .swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
    visibility: hidden !important;
    transition: opacity 0.2s linear, visibility 0.2s;
  }
}

.accordion {
  .accordion-item {
    border-left: none !important;
    border-right: none !important;
    .accordion-header {
      .accordion-button {
        box-shadow: none !important;
        font-size: 16px !important;
        font-weight: 500 !important;
      }
    }
    .accordion-body {
      border: none !important;
      border-top: 1px solid $fuMidGrayColor !important;
      background-color: $fuDarkGrayColor !important;
      transition: border 0.2s ease;
    }
  }
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-border-radius: 0;
  --bs-accordion-border-color: #2f2f2f;
  --bs-accordion-bg: #191919;
  --bs-accordion-color: #a5a5a5;
  --bs-accordion-btn-color: #fdb915;
  --bs-accordion-btn-bg: #191919;
  --bs-accordion-active-bg: #191919;
  --bs-accordion-active-color: #fdb915;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23a5a5a5%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fdb915%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.footer-copyrights {
  display: flex;
  align-items: center;
  .copyrights-text {
    display: inline-flex;
  }
  .author-text {
    & {
      display: inline-flex;
      align-items: center;
    }
    .dash {
      display: inline-flex;
      width: 10px;
      height: 3px;
      border-radius: 8px;
      background-color: $fuYellowColor;
    }
  }
}

.subsribe-input-container {
  position: relative;
  .subscribe-input-loading {
    background-color: inherit;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.fu-alert-yellow {
  color: $fuWhiteColor;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid rgba($color: $fuYellowColor, $alpha: 0.6);
  background-color: rgba($color: $fuYellowColor, $alpha: 0.4);
  padding: 6px 14px !important;
}
