@import "../../styles/index.scss";

.blog_heading_container {
  & {
    position: relative;
    background-color: $fuLightBlackColor;
    padding-bottom: 200px;
    margin-bottom: -200px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  .blog_img_section {
    & {
      position: relative;
      z-index: 10;
    }
    .blog_pic_container {
      width: 1296px;
      height: 479.7px;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .bg_blur {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow-y: hidden;
    filter: blur(50px);
    opacity: 0.2;
  }
  .gradient {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    background-image: linear-gradient(to top, $fuDarkGrayColor, rgba($color: $fuDarkGrayColor, $alpha: 0.7), rgba($color: $fuDarkGrayColor, $alpha: 0.3), $fuTransparentColor);
  }
}

.created_at_box {
  & {
    display: flex;
    align-items: center;
  }
  .calendar_icon_container {
    display: inline-flex;
    font-size: 14px;
    color: $fuYellowColor;
  }
  .date_container {
    margin-left: 8px;
    .creation_date {
      font-size: 16px;
      color: $fuLightGrayColor;
    }
  }
}

.share_row {
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .share_box_container {
    & {
      display: flex;
      align-items: center;
    }
    .share_btn_container {
      margin-right: 6px;
    }
    .share_icons_box {
      .share_icon_container {
        & {
          display: flex;
          align-items: center;
        }
        .social_icon_container {
          cursor: pointer;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 2px solid $fuLightGrayColor;
          background-color: $fuTransparentColor;
          margin: 0px 6px;
          transition: border-color 0.3s ease-in-out, margin 0.2s ease-in-out;
          .social_icon {
            fill: $fuLightGrayColor;
            transition: all 0.3s;
          }
          &:hover {
            border-color: $fuYellowColor;
            .social_icon {
              fill: $fuYellowColor;
            }
          }
        }
      }
    }
  }
}

.blog_title_container {
  margin-bottom: 22px;
  .blog_title {
    font-size: 24px;
    font-weight: 700;
    color: $fuWhiteColor;
    line-height: 1.2 !important;
  }
}

.blog_description_container {
  .blog_description {
    font-size: 16px;
    color: $fuLightGrayColor;
  }
}

// VS
@media screen and (min-width: 0) and (max-width: 310px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: auto;
        height: auto;
      }
    }
  }

  .blog_title_container {
    margin-bottom: 12px;
    .blog_title {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 12px;
    }
  }

  .created_at_box {
    .calendar_icon_container {
      font-size: 12px;
    }
    .date_container {
      .creation_date {
        font-size: 12px;
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 22px;
            height: 22px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 12px;
            }
          }
        }
      }
    }
  }
}
// XXXS
@media screen and (min-width: 310px) and (max-width: 399.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: auto;
        height: auto;
      }
    }
  }

  .blog_title_container {
    margin-bottom: 12px;
    .blog_title {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 12px;
    }
  }

  .created_at_box {
    .calendar_icon_container {
      font-size: 12px;
    }
    .date_container {
      .creation_date {
        font-size: 12px;
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 25px;
            height: 25px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// XXS
@media screen and (min-width: 400px) and (max-width: 479.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: auto;
        height: auto;
      }
    }
  }

  .blog_title_container {
    margin-bottom: 12px;
    .blog_title {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 12px;
    }
  }

  .created_at_box {
    .calendar_icon_container {
      font-size: 12px;
    }
    .date_container {
      .creation_date {
        font-size: 12px;
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// XS
@media screen and (min-width: 480px) and (max-width: 575.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 440px;
        height: 162.86px;
      }
    }
  }

  .blog_title_container {
    margin-bottom: 12px;
    .blog_title {
      font-size: 18px;
      font-weight: 400;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 14px;
    }
  }

  .created_at_box {
    .calendar_icon_container {
      font-size: 12px;
    }
    .date_container {
      .creation_date {
        font-size: 12px;
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// SM
@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 516px;
        height: 190.98px;
      }
    }
  }

  .blog_title_container {
    margin-bottom: 12px;
    .blog_title {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 14px;
    }
  }

  .created_at_box {
    .calendar_icon_container {
      font-size: 12px;
    }
    .date_container {
      .creation_date {
        font-size: 12px;
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// MD
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 696px;
        height: 257.63px;
      }
    }
  }

  .blog_title_container {
    margin-bottom: 18px;
    .blog_title {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 14px;
    }
  }

  .created_at_box {
    .calendar_icon_container {
      font-size: 14px;
    }
    .date_container {
      .creation_date {
        font-size: 14px;
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// LG
@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 936px;
        height: 346.45px;
      }
    }
  }
  .blog_title_container {
    margin-bottom: 18px;
    .blog_title {
      font-size: 19px;
      font-weight: 700;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 15px;
    }
  }

  .created_at_box {
    .calendar_icon_container {
      font-size: 14px;
    }
    .date_container {
      .creation_date {
        font-size: 14px;
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// XL
@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 1116px;
        height: 413.08px;
      }
    }
  }
  .blog_title_container {
    margin-bottom: 18px;
    .blog_title {
      font-size: 22px;
      font-weight: 700;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 16px;
    }
  }

  .created_at_box {
    .calendar_icon_container {
      font-size: 14px;
    }
    .date_container {
      .creation_date {
        font-size: 14px;
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
