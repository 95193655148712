@import "../../styles/index.scss";

.release_container {
  position: relative;
  background-color: $fuLightBlackColor;
  padding-bottom: 500px;
  margin-bottom: -500px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  .release_info_section {
    & {
      position: relative;
      z-index: 10;
    }
    .release_info {
      .release_title {
        color: $fuWhiteColor;
        font-size: 2rem;
        font-weight: 700;
        // line-height: 1;
      }
      .release_artist,
      .release_date,
      .release_catalog {
        display: block;
        font-size: 16px;
        font-weight: 300;
        color: $fuLightGrayColor;
      }
      .date,
      .catalog {
        margin-left: 5px;
        color: $fuWhiteColor;
        font-weight: 600;
      }
      .release_caption {
        color: $fuWhiteColor;
        font-weight: 200;
        font-size: 16px;
      }
    }
    .release_img {
      width: 500px;
      height: 500px;
      margin-left: auto;
      overflow: hidden;
      border-radius: 4px;
    }
    .stream_icons_container {
      display: flex;
      flex-wrap: wrap;
      .stream_icon {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 2px solid $fuLightGrayColor;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        transition: all 0.3s;
        .svg_icon {
          fill: $fuLightGrayColor;
          transition: all 0.3s;
        }
        &:hover {
          border-color: $fuYellowColor;
          .svg_icon {
            fill: $fuYellowColor;
          }
        }
      }
    }
  }

  .bg_blur {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow-y: hidden;
    filter: blur(50px);
    opacity: 0.2;
  }
  .gradient {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 600px;
    background-image: linear-gradient(to top, $fuDarkGrayColor 25%, rgba($color: $fuDarkGrayColor, $alpha: 0.7) 50%, rgba($color: $fuDarkGrayColor, $alpha: 0.3) 75%, $fuTransparentColor);
  }
}

.share_row {
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .share_box_container {
    & {
      display: flex;
      align-items: center;
    }
    .share_btn_container {
      margin-right: 6px;
    }
    .share_icons_box {
      .share_icon_container {
        & {
          display: flex;
          align-items: center;
        }
        .social_icon_container {
          cursor: pointer;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 2px solid $fuLightGrayColor;
          background-color: $fuTransparentColor;
          margin: 0px 6px;
          transition: border-color 0.3s ease-in-out, margin 0.2s ease-in-out;
          .social_icon {
            fill: $fuLightGrayColor;
            transition: all 0.3s;
          }
          &:hover {
            border-color: $fuYellowColor;
            .social_icon {
              fill: $fuYellowColor;
            }
          }
        }
      }
    }
  }
}

// VS
@media screen and (min-width: 0px) and (max-width: 310px) {
  .release_container {
    .release_info_section {
      .release_info {
        .release_title {
          font-size: 18px;
        }
        .release_artist,
        .release_date,
        .release_catalog {
          font-size: 14px;
        }

        .release_caption {
          font-weight: 200;
          font-size: 14px;
        }
      }
      .stream_icons_container {
        .stream_icon {
          width: 25px;
          height: 25px;
          .svg_icon {
            width: 12px !important;
            fill: $fuLightGrayColor;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 22px;
            height: 22px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 12px;
            }
          }
        }
      }
    }
  }
}
// XXXS
@media screen and (min-width: 310px) and (max-width: 400px) {
  .release_container {
    .release_info_section {
      .release_info {
        .release_title {
          font-size: 20px;
        }
        .release_artist,
        .release_date,
        .release_catalog {
          font-size: 14px;
        }

        .release_caption {
          font-weight: 200;
          font-size: 14px;
        }
      }
      .stream_icons_container {
        .stream_icon {
          width: 28px;
          height: 28px;
          .svg_icon {
            width: 13px !important;
            fill: $fuLightGrayColor;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 25px;
            height: 25px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// XXS
@media screen and (min-width: 400px) and (max-width: 479.9px) {
  .release_container {
    .release_info_section {
      .release_info {
        .release_title {
          font-size: 1.6rem;
        }
      }
      .stream_icons_container {
        .stream_icon {
          width: 30px;
          height: 30px;
          .svg_icon {
            width: 15px !important;
            fill: $fuLightGrayColor;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// XS
@media screen and (min-width: 480px) and (max-width: 575.9px) {
  .release_container {
    .release_info_section {
      .release_info {
        .release_title {
          font-size: 1.6rem;
        }
      }
      .stream_icons_container {
        .stream_icon {
          width: 30px;
          height: 30px;
          .svg_icon {
            width: 15px !important;
            fill: $fuLightGrayColor;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// SM
@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// MD
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// LG
@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
// XL
@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .share_row {
    & {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .share_box_container {
      .share_btn_container {
        margin-right: 4px;
      }
      .share_icons_box {
        .share_icon_container {
          .social_icon_container {
            width: 26px;
            height: 26px;
            border: 1px solid $fuLightGrayColor;
            margin: 0px 4px;
            .social_icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
