@import "../../styles/index.scss";

.contact_row_heading {
  .row_title {
    position: relative;
    overflow: hidden;
    font-size: 20px;
    font-weight: 600;
    // letter-spacing: 2px;
    color: $fuWhiteColor;
    &::after {
      content: "";
      position: absolute;
      margin-left: 5px;
      top: 50%;
      transform: translateY(50%);
      width: 100%;
      height: 1px;
      background-color: $fuWhiteColor;
    }
  }
}

.contact_circle {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: $fuYellowColor;
  border: 3px solid $fuDarkGrayColor;
  outline: 3px solid $fuYellowColor;
  display: flex;
  align-items: center;
  justify-content: center;
  .contact_icon {
    fill: $fuDarkGrayColor;
    width: 30px;
    height: 30px;
  }
}
.contact_info_title {
  color: $fuWhiteColor;
  font-size: 16px;
  font-weight: 600;
}
.contact_info_desc {
  color: $fuLightGrayColor;
  font-size: 16px;
  font-weight: 400;
}

.contact_form_box {
  & {
    position: relative;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    // width: 1px;
    // background-color: $fuLightGrayColor;
    // border-left: 1px dashed $fuLightGrayColor;
  }
  border-radius: 4px;
  .form_title {
    font-size: 20px;
  }
}

// VS
@media screen and (min-width: 0) and (max-width: 310px) {
  .contact_form_box {
    .form_title {
      font-size: 16px;
    }
  }
  .contact_circle {
    width: 35px;
    height: 35px;
    border: 2px solid $fuDarkGrayColor;
    outline: 2px solid $fuYellowColor;
    .contact_icon {
      width: 20px;
      height: 20px;
    }
  }
  .contact_info_title {
    font-size: 14px;
    font-weight: 600;
  }
  .contact_info_desc {
    font-size: 12px;
    font-weight: 400;
  }
}
// XXXS
@media screen and (min-width: 310px) and (max-width: 399.9px) {
  .contact_form_box {
    .form_title {
      font-size: 16px;
    }
  }
  .contact_circle {
    width: 35px;
    height: 35px;
    border: 2px solid $fuDarkGrayColor;
    outline: 2px solid $fuYellowColor;
    .contact_icon {
      width: 20px;
      height: 20px;
    }
  }
  .contact_info_title {
    font-size: 14px;
    font-weight: 600;
  }
  .contact_info_desc {
    font-size: 12px;
    font-weight: 400;
  }
}
// XXS
@media screen and (min-width: 400px) and (max-width: 479.9px) {
  .contact_form_box {
    .form_title {
      font-size: 16px;
    }
  }
  .contact_circle {
    width: 35px;
    height: 35px;
    border: 2px solid $fuDarkGrayColor;
    outline: 2px solid $fuYellowColor;
    .contact_icon {
      width: 20px;
      height: 20px;
    }
  }
  .contact_info_title {
    font-size: 14px;
    font-weight: 600;
  }
  .contact_info_desc {
    font-size: 12px;
    font-weight: 400;
  }
}
// XS
@media screen and (min-width: 480px) and (max-width: 575.9px) {
  .contact_form_box {
    .form_title {
      font-size: 16px;
    }
  }
  .contact_circle {
    margin-left: 5px;
    width: 35px;
    height: 35px;
    border: 2px solid $fuDarkGrayColor;
    outline: 2px solid $fuYellowColor;
    .contact_icon {
      width: 20px;
      height: 20px;
    }
  }
  .contact_info_title {
    font-size: 14px;
    font-weight: 600;
  }
  .contact_info_desc {
    font-size: 12px;
    font-weight: 400;
  }
}
// SM
@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .contact_form_box {
    .form_title {
      font-size: 18px;
    }
  }
  .contact_circle {
    width: 40px;
    height: 40px;
    border: 2px solid $fuDarkGrayColor;
    outline: 2px solid $fuYellowColor;
    .contact_icon {
      width: 25px;
      height: 25px;
    }
  }
  .contact_info_title {
    font-size: 14px;
    font-weight: 600;
  }
  .contact_info_desc {
    font-size: 12px;
    font-weight: 400;
  }
}
// MD
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .contact_form_box {
    .form_title {
      font-size: 18px;
    }
  }
  .contact_circle {
    width: 40px;
    height: 40px;
    border: 2px solid $fuDarkGrayColor;
    outline: 2px solid $fuYellowColor;
    .contact_icon {
      width: 25px;
      height: 25px;
    }
  }
  .contact_info_title {
    font-size: 16px;
    font-weight: 600;
  }
  .contact_info_desc {
    font-size: 14px;
    font-weight: 400;
  }
}
// XL
@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .contact_circle {
    width: 40px;
    height: 40px;
    border: 2px solid $fuDarkGrayColor;
    outline: 2px solid $fuYellowColor;
    .contact_icon {
      width: 25px;
      height: 25px;
    }
  }
  .contact_info_title {
    font-size: 16px;
    font-weight: 600;
  }
  .contact_info_desc {
    font-size: 14px;
    font-weight: 400;
  }
}
