@import "../../styles/index.scss";

.contact_row_heading {
  .row_title {
    position: relative;
    overflow: hidden;
    font-size: 20px;
    font-weight: 600;
    // letter-spacing: 2px;
    color: $fuWhiteColor;
    &::after {
      content: "";
      position: absolute;
      margin-left: 5px;
      top: 50%;
      transform: translateY(50%);
      width: 100%;
      height: 1px;
      background-color: $fuWhiteColor;
    }
  }
}

.contact_circle {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: $fuYellowColor;
  border: 3px solid $fuDarkGrayColor;
  outline: 3px solid $fuYellowColor;
  display: flex;
  align-items: center;
  justify-content: center;
  .contact_icon {
    fill: $fuDarkGrayColor;
    width: 30px;
    height: 30px;
  }
}

.contact_form_box {
  & {
    position: relative;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    // width: 1px;
    // background-color: $fuLightGrayColor;
    // border-left: 1px dashed $fuLightGrayColor;
  }
  border-radius: 4px;
}

.social_box {
  & {
    display: flex;
  }
  .social_icon_container {
    & {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $fuTransparentColor;
      margin: 0 5px;
      transition: all 0.3s;
    }
    .social_icon {
      fill: $fuLightGrayColor;
      transition: all 0.3s;
    }
    &:hover {
      .social_icon {
        fill: $fuYellowColor;
      }
    }
  }
}
