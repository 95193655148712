@import "./colors";
@import "./mixins";

@media screen and (min-width: 0) and (max-width: 1249.9px) {
  .layout-container {
    display: block;
  }
  .fu-btn {
    padding: 5px 14px;
    font-size: 14px;
  }
  .item-search-box-container {
    input {
      padding: 4px 14px !important;
    }
  }

  .outlet {
    transform: unset;
  }

  .offcanvas[aria-modal="true"] {
    width: 22rem;
  }

  .mobile-nav {
    .mobile-side-nav {
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar-button:single-button:vertical:decrement {
        display: none;
      }
      &::-webkit-scrollbar-button:single-button:vertical:increment {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-moz-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: $fuBlackColor;
      }

      &::-moz-scrollbar-track {
        background-color: $fuBlackColor;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;

        border: 1px solid transparent;
        background-clip: content-box;
        background-color: #fdb915;
        transition: all 0.2s;
        &:hover {
          background-color: #d19914;
        }
      }

      &::-moz-scrollbar-thumb {
        border-radius: 8px;
        border: 1px solid transparent;
        background-clip: content-box;
        background-color: #fdb915;
        transition: all 0.2s;
        &:hover {
          background-color: #d19914;
        }
      }
    }
    .mobile-nav-home-btn {
      position: relative;
      .close-mobile-nav-btn {
        opacity: 0;
        width: 35px;
        height: 35px;
        position: absolute;
        top: calc(50% + 10px);
        right: 0;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: $fuDarkGrayColor;
        border: 2px solid $fuLightGrayColor;
        color: $fuLightGrayColor;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        &:hover {
          color: $fuYellowColor;
          border-color: $fuYellowColor;
        }
      }
    }

    .show {
      .logo-container {
        .logo-width {
          transform: translateX(-10px);
          opacity: 1;
          transition: opacity 0.4s, transform 0.3s;
          transition-delay: 0.2s;
        }
      }
      .nav-link-container {
        .link-text-container {
          transform: translateX(-10px);
          opacity: 1;
          transition: opacity 0.4s, transform 0.3s !important;
        }
        @for $i from 1 through 11 {
          .link-text-container-#{$i} {
            transition-delay: $i * 0.04s !important;
          }
        }
      }
      .mobile-nav-home-btn {
        .close-mobile-nav-btn {
          opacity: 1;
        }
      }
    }
  }

  .virtual-mobile-bar-height {
    background-color: $fuDarkGrayColor;
    height: 64px;
    width: 100%;
  }

  .mobile-nav:has(> .show) + .mobile-bar-container {
    .bar-btn {
      color: $fuYellowColor !important;
      transition: all 0.3s !important;
    }
  }

  .offcanvas-backdrop {
    z-index: 9999999 !important;
  }

  .custom-height {
    min-height: calc(100vh - 24px - 64px);
  }
}
// VS
@media screen and (min-width: 0) and (max-width: 310px) {
  [data-title]:after {
    font-size: 6px;
    padding: 2px 2px;
  }
  .search-modal {
    .modal-box {
      width: 90%;
      .search-modal-title {
        font-size: 16px;
      }
    }
  }
  .demo-drop-explain {
    font-size: 14px;
  }

  .fm-about-description {
    font-size: 12px;
  }

  .accordion {
    .accordion-item {
      .accordion-header {
        .accordion-button {
          font-weight: 400 !important;
        }
      }
    }
  }
  .fu-btn {
    padding: 5px 12px;
    font-size: 12px;
  }
  .fu-col-vs-12 {
    flex: 0 0 auto !important;
    width: 100% !important;
  }
  .col-vs-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
  .item-row-heading {
    .row-title {
      font-size: 16px;
    }
  }

  .fu-btn-sm {
    padding: 4px 10px;
    font-size: 12px;
  }
  .fu-breadcrumb {
    .fu-breadcrumb-item {
      .fu-link-text {
        font-size: 10px;
      }
    }
  }

  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0.5;
      visibility: visible;
      width: 30px;
      height: 30px;
      &::after {
        font-size: 12px !important;
      }
    }
  }

  .swal2-popup {
    .swal2-icon {
      & {
        margin-top: 2rem;
        width: 2.5rem;
        height: 2.5rem;
      }
      .swal2-icon-content {
        font-size: 2rem;
      }
    }
    .swal2-title {
      font-size: 1.5rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 14px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .Toastify__toast-container {
    top: 16px !important;
    left: unset !important;
    .Toastify__toast {
      border-radius: 4px;
      min-height: 44px;
      margin-bottom: 12px;
      .Toastify__toast-body {
        font-size: 12px;
        padding: 0 6px;
        margin: 0;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 200px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .mobile-nav {
    .mobile-nav-home-btn {
      .close-mobile-nav-btn {
        width: 25px;
        height: 25px;
        font-size: 16px;
      }
    }
    .icon-container {
      .icon-width {
        width: 60px;
        .icon {
          width: 26px !important;
        }
      }
    }
    .logo-container {
      .logo-width {
        width: 120px;
        .logo {
          width: 120px;
        }
      }
    }
  }

  .offcanvas[aria-modal="true"] {
    width: 16rem;
  }

  .release-container {
    width: 100%;
    .release-img-container {
      width: auto;
      height: auto;
      .listen-now-layer {
        .listen-text {
          font-size: 14px;
        }
      }
    }
  }

  .top-five-circle,
  .pre-order-circle {
    width: 15px;
    height: 15px;
    font-size: 7px;
    .top-five-order {
      font-size: 9px;
      font-weight: 500;
    }
  }

  .details-top-five-circle,
  .details-pre-order-circle {
    & {
      width: 16px;
      height: 16px;
      font-size: 8px;
    }
    .top-five-order {
      font-size: 10px;
      font-weight: 500;
    }
  }

  .video-container {
    width: 100%;
    height: auto;
    margin: auto;
    .yt-iframe-box {
      .i-frame-container {
        aspect-ratio: 16 / 9;
        .i-frame-play-icon {
          width: 35px;
          height: 35px;
          font-size: 20px;
        }
      }
    }
  }

  .release-caption,
  .fu-video-caption,
  .fu-blog-caption {
    .release-name,
    .fu-release-video-name,
    .fu-blog-title {
      -webkit-line-clamp: 1;
      font-size: 14px;
    }
    .release-artist,
    .fu-channel-video-name {
      font-size: 12px;
    }
  }

  .artist-box,
  .label-box {
    width: auto;
    margin: auto;
    &:hover {
      .artist-img-container,
      .label-img-container {
        border-color: $fuYellowColor;
        .verification-circle {
          border-color: $fuYellowColor;
        }
      }
    }
    .artist-img-container,
    .label-img-container {
      & {
        border: 1px solid $fuWhiteColor;
      }
      .artist-img,
      .label-img {
        width: auto;
        height: auto;
      }
      .verification-circle {
        & {
          border: 1px solid $fuWhiteColor;
          width: 19px;
          height: 19px;
        }
        .futurising-icon {
          width: 10px;
          height: 10px;
        }
      }
    }

    .artist-name-container,
    .label-name-container {
      .artist-name,
      .label-name {
        font-size: 14px;
        font-weight: 400 !important;
      }
    }
  }

  .team-member-box {
    .img-container {
      & {
        border: 2px solid $fuWhiteColor;
      }
      .img {
        width: auto;
        height: auto;
      }
    }
    .name-container {
      .name {
        font-size: 14px;
        font-weight: 500 !important;
      }
    }
    .job-title-container {
      .job-title {
        font-size: 12px;
      }
    }
  }

  .blog-box {
    .blog-img-container {
      width: auto;
      height: auto;
    }
  }

  .item-msg {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .subscribe-box {
    & {
      flex-direction: column !important;
    }
    .subsribe-input-container {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
    .subscribe-btn-container {
      align-self: flex-start !important;
    }
  }

  .footer-copyrights {
    flex-direction: column;
    .copyrights-text {
      font-size: 12px !important;
      margin-bottom: 8px;
    }
    .author-text {
      & {
        font-size: 12px !important;
      }
      .dash {
        display: none;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 200px;
    }
  }

  .page-heading-title {
    font-size: 2.3rem;
    font-weight: 700;
  }

  .page-heading-long-title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .privacy-policies-description {
    & {
      font-size: 14px;
      font-weight: 500;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h5 {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h6 {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    a {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .fu-alert-yellow {
    font-weight: 400;
    font-size: 12px;
    padding: 4px 10px !important;
  }
}
// XXXS
@media screen and (min-width: 310px) and (max-width: 399.9px) {
  [data-title]:after {
    font-size: 6px;
    padding: 2px 2px;
  }
  .search-modal {
    .modal-box {
      width: 90%;
      .search-modal-title {
        font-size: 16px;
      }
    }
  }
  .demo-drop-explain {
    font-size: 14px;
  }

  .fm-about-description {
    font-size: 14px;
  }
  .accordion {
    .accordion-item {
      .accordion-header {
        .accordion-button {
          font-weight: 400 !important;
        }
      }
    }
  }
  .fu-col-xxxs-12 {
    flex: 0 0 auto !important;
    width: 100% !important;
  }
  .col-xxxs-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
  .item-row-heading {
    .row-title {
      font-size: 16px;
    }
  }

  .fu-btn-sm {
    padding: 4px 10px;
    font-size: 12px;
  }

  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0.5;
      visibility: visible;
      width: 30px;
      height: 30px;
      &::after {
        font-size: 12px !important;
      }
    }
  }

  .swal2-popup {
    & {
      width: 270px;
    }
    .swal2-icon {
      & {
        margin-top: 2rem;
        width: 2.5rem;
        height: 2.5rem;
      }
      .swal2-icon-content {
        font-size: 2rem;
      }
    }
    .swal2-title {
      font-size: 1.5rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 14px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .Toastify__toast-container {
    top: 16px !important;
    left: unset !important;
    .Toastify__toast {
      border-radius: 4px;
      min-height: 44px;
      margin-bottom: 12px;
      .Toastify__toast-body {
        font-size: 14px;
        padding: 0 6px;
        margin: 0;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 250px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .mobile-nav {
    .mobile-nav-home-btn {
      .close-mobile-nav-btn {
        width: 30px;
        height: 30px;
        font-size: 18px;
      }
    }
    .icon-container {
      .icon-width {
        width: 60px;
        .icon {
          width: 26px !important;
        }
      }
    }
    .logo-container {
      .logo-width {
        width: 120px;
        .logo {
          width: 120px;
        }
      }
    }
  }

  .offcanvas[aria-modal="true"] {
    width: 17rem;
  }

  .release-container {
    width: auto;
    margin: auto;
    .pre-order-label-container {
      height: 20px;
      font-size: 12px;
      transform: rotate(-45deg) translateX(-63px) translateY(-30px);
    }
    .release-img-container {
      width: auto;
      height: auto;
      .listen-now-layer {
        padding: 14px 0;
        .listen-text {
          font-size: 14px;
          letter-spacing: 0;
          font-weight: 600;
        }
      }
    }
  }

  .top-five-circle,
  .pre-order-circle {
    width: 15px;
    height: 15px;
    font-size: 7px;
    .top-five-order {
      font-size: 9px;
      font-weight: 500;
    }
  }

  .details-top-five-circle,
  .details-pre-order-circle {
    & {
      width: 16px;
      height: 16px;
      font-size: 8px;
    }
    .top-five-order {
      font-size: 10px;
      font-weight: 500;
    }
  }

  .video-container {
    width: auto;
    margin: auto;
    .yt-iframe-box {
      .i-frame-container {
        height: auto;
        height: auto;
        aspect-ratio: 16 / 9;
        .i-frame-play-icon {
          width: 30px;
          height: 30px;
          font-size: 20px;
        }
      }
    }
  }

  .release-caption,
  .fu-video-caption,
  .fu-blog-caption {
    .release-name,
    .fu-release-video-name,
    .fu-blog-title {
      -webkit-line-clamp: 1;
      font-size: 14px;
    }
    .release-artist,
    .fu-channel-video-name {
      font-size: 12px;
    }
  }

  .new-blog-container {
    width: auto;
    margin: auto;
  }

  .blog-box {
    .blog-img-container {
      width: auto;
      height: auto;
    }
  }

  .artist-box,
  .label-box {
    width: auto;
    margin: auto;
    &:hover {
      .artist-img-container,
      .label-img-container {
        border-color: $fuYellowColor;
        .verification-circle {
          border-color: $fuYellowColor;
        }
      }
    }
    .artist-img-container,
    .label-img-container {
      & {
        border: 1px solid $fuWhiteColor;
      }
      .artist-img,
      .label-img {
        width: auto;
        height: auto;
      }
      .verification-circle {
        & {
          width: 19px;
          height: 19px;
          border: 1px solid $fuWhiteColor;
        }
        .futurising-icon {
          width: 10px;
          height: 10px;
        }
      }
    }

    .artist-name-container,
    .label-name-container {
      .artist-name,
      .label-name {
        font-size: 14px;
        font-weight: 400 !important;
      }
    }
  }

  .team-member-box {
    .img-container {
      & {
        border: 2px solid $fuWhiteColor;
      }
      .img {
        width: auto;
        height: auto;
      }
    }
    .name-container {
      .name {
        font-size: 14px;
        font-weight: 500 !important;
      }
    }
    .job-title-container {
      .job-title {
        font-size: 12px;
      }
    }
  }

  .item-msg {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .subscribe-box {
    & {
      flex-direction: column !important;
    }
    .subsribe-input-container {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
    .subscribe-btn-container {
      align-self: flex-start !important;
    }
  }

  .footer-copyrights {
    flex-direction: column;
    .copyrights-text {
      font-size: 12px !important;
      margin-bottom: 8px;
    }
    .author-text {
      & {
        font-size: 12px !important;
      }
      .dash {
        display: none;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 250px;
    }
  }

  .page-heading-title {
    font-size: 3rem;
  }
  .page-heading-long-title {
    font-size: 2rem;
  }

  .privacy-policies-description {
    & {
      font-size: 14px;
      font-weight: 500;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h5 {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h6 {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    a {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .fu-alert-yellow {
    font-weight: 400;
    font-size: 12px;
    padding: 4px 10px !important;
  }
}
// XXS
@media screen and (min-width: 400px) and (max-width: 479.9px) {
  [data-title]:after {
    font-size: 6px;
    padding: 2px 2px;
  }
  .search-modal {
    .modal-box {
      width: 90%;
      .search-modal-title {
        font-size: 16px;
      }
    }
  }
  .demo-drop-explain {
    font-size: 15px;
  }

  .fm-about-description {
    font-size: 14px;
  }
  .accordion {
    .accordion-item {
      .accordion-header {
        .accordion-button {
          font-weight: 400 !important;
        }
      }
    }
  }
  .col-xxs-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
  .col-xxs-4 {
    flex: 0 0 auto !important;
    width: 33.33333333% !important;
  }

  .fu-btn-sm {
    padding: 4px 10px;
    font-size: 12px;
  }

  .item-row-heading {
    .row-title {
      font-size: 18px;
    }
  }

  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0.5;
      visibility: visible;
      width: 35px;
      height: 35px;
      &::after {
        font-size: 12px !important;
      }
    }
  }

  .swal2-popup {
    & {
      width: 330px;
    }
    .swal2-icon {
      & {
        margin-top: 2rem;
        width: 2.9rem;
        height: 2.9rem;
      }
      .swal2-icon-content {
        font-size: 2.2rem;
      }
    }
    .swal2-title {
      font-size: 1.5rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 14px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .Toastify__toast-container {
    top: 16px !important;
    left: unset !important;
    .Toastify__toast {
      border-radius: 4px;
      min-height: 44px;
      margin-bottom: 12px;
      .Toastify__toast-body {
        font-size: 14px;
        padding: 0 6px;
        margin: 0;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 300px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .mobile-nav {
    .mobile-nav-home-btn {
      .close-mobile-nav-btn {
        width: 35px;
        height: 35px;
        font-size: 20px;
      }
    }
    .icon-container {
      .icon-width {
        width: 60px;
        .icon {
          width: 26px !important;
        }
      }
    }
    .logo-container {
      .logo-width {
        width: 120px;
        .logo {
          width: 120px;
        }
      }
    }
  }

  .offcanvas[aria-modal="true"] {
    width: 19rem;
  }

  .video-container {
    width: auto;
    margin: auto;
    .yt-iframe-box {
      .i-frame-container {
        height: auto;
        height: auto;
        aspect-ratio: 16 / 9;
        .i-frame-play-icon {
          width: 30px;
          height: 30px;
          font-size: 20px;
        }
      }
    }
  }

  .release-container {
    width: auto;
    margin: auto;
    .pre-order-label-container {
      height: 20px;
      font-size: 12px;
      transform: rotate(-45deg) translateX(-63px) translateY(-30px);
    }
    .release-img-container {
      width: auto;
      height: auto;
      .listen-now-layer {
        padding: 8px 0;
        .listen-text {
          font-size: 12px;
          letter-spacing: 0;
          font-weight: 600;
        }
      }
    }
  }

  .top-five-circle,
  .pre-order-circle {
    width: 18px;
    height: 18px;
    font-size: 7px;
    .top-five-order {
      font-size: 9px;
      font-weight: 500;
    }
  }

  .details-top-five-circle,
  .details-pre-order-circle {
    & {
      width: 18px;
      height: 18px;
      font-size: 8px;
    }
    .top-five-order {
      font-size: 10px;
      font-weight: 500;
    }
  }

  .release-caption,
  .fu-video-caption,
  .fu-blog-caption {
    .release-name,
    .fu-release-video-name,
    .fu-blog-title {
      -webkit-line-clamp: 1;
      font-size: 14px;
    }
    .release-artist,
    .fu-channel-video-name {
      font-size: 12px;
    }
  }

  .artist-box,
  .label-box {
    width: auto;
    margin: auto;
    &:hover {
      .artist-img-container,
      .label-img-container {
        border-color: $fuYellowColor;
        .verification-circle {
          border-color: $fuYellowColor;
        }
      }
    }
    .artist-img-container,
    .label-img-container {
      & {
        border: 1px solid $fuWhiteColor;
      }
      .artist-img,
      .label-img {
        width: auto;
        height: auto;
      }
      .verification-circle {
        & {
          width: 19px;
          height: 19px;
          border: 1px solid $fuWhiteColor;
        }
        .futurising-icon {
          width: 10px;
          height: 10px;
        }
      }
    }

    .artist-name-container,
    .label-name-container {
      .artist-name,
      .label-name {
        font-size: 14px;
        font-weight: 400 !important;
      }
    }
  }

  .team-member-box {
    .img-container {
      & {
        border: 2px solid $fuWhiteColor;
      }
      .img {
        width: 150px;
        height: 150px;
      }
    }
    .name-container {
      .name {
        font-size: 14px;
        font-weight: 500 !important;
      }
    }
    .job-title-container {
      .job-title {
        font-size: 12px;
      }
    }
  }

  .new-blog-container {
    width: auto;
    margin: auto;
  }

  .blog-box {
    .blog-img-container {
      width: auto;
      height: auto;
    }
  }

  .item-msg {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .footer-copyrights {
    flex-direction: column;
    .copyrights-text {
      font-size: 14px !important;
      margin-bottom: 8px;
    }
    .author-text {
      & {
        font-size: 14px !important;
      }
      .dash {
        display: none;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 300px;
    }
  }

  .page-heading-title {
    font-size: 3.2rem;
  }

  .page-heading-long-title {
    font-size: 2.6rem;
  }

  .privacy-policies-description {
    & {
      font-size: 14px;
      font-weight: 500;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h5 {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h6 {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    a {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .fu-alert-yellow {
    font-weight: 400;
    font-size: 14px;
    padding: 4px 10px !important;
  }
}
// XS
@media screen and (min-width: 480px) and (max-width: 575.9px) {
  [data-title]:after {
    font-size: 8px;
    padding: 2px 2px;
  }
  .search-modal {
    .modal-box {
      width: 80%;
      .search-modal-title {
        font-size: 16px;
      }
    }
  }
  .fm-about-description {
    font-size: 14px;
  }
  .accordion {
    .accordion-item {
      .accordion-header {
        .accordion-button {
          font-weight: 400 !important;
        }
      }
    }
  }
  .col-xs-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
  .col-xs-4 {
    flex: 0 0 auto !important;
    width: 33.33333333% !important;
  }

  .container {
    width: 440px;
    padding: 0;
    overflow: hidden;
  }

  .item-row-heading {
    .row-title {
      font-size: 18px;
    }
  }

  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0.5;
      visibility: visible;
      width: 35px;
      height: 35px;
      &::after {
        font-size: 12px !important;
      }
    }
  }

  .swal2-popup {
    & {
      width: 360px;
    }
    .swal2-icon {
      & {
        margin-top: 2.3rem;
        width: 3.5rem;
        height: 3.5rem;
      }
      .swal2-icon-content {
        font-size: 2.8rem;
      }
    }
    .swal2-title {
      font-size: 1.5rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 320px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        font-size: 14px;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .mobile-nav {
    .icon-container {
      .icon-width {
        width: 60px;
        .icon {
          width: 26px !important;
        }
      }
    }
    .logo-container {
      .logo-width {
        width: 120px;
        .logo {
          width: 120px;
        }
      }
    }
  }

  .offcanvas[aria-modal="true"] {
    width: 20rem;
  }

  .item-msg {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .video-container {
    width: 440px;
    margin: auto;
    .yt-iframe-box {
      .i-frame-container {
        height: 237.94px;
        aspect-ratio: 16 / 9;
      }
    }
  }

  .new-blog-container {
    width: 440px;
    margin: auto;
  }

  .blog-box {
    .blog-img-container {
      width: 424px;
      height: 156.94px;
    }
  }

  .release-container {
    width: 215px;
    margin: auto;
    .pre-order-label-container {
      height: 22px;
      font-size: 12px;
    }
    .release-img-container {
      width: 199px;
      height: 199px;
      .listen-now-layer {
        padding: 8px 0;
        .listen-text {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  .top-five-circle,
  .pre-order-circle {
    width: 18px;
    height: 18px;
    font-size: 7px;
    .top-five-order {
      font-size: 9px;
      font-weight: 500;
    }
  }

  .details-top-five-circle,
  .details-pre-order-circle {
    & {
      width: 18px;
      height: 18px;
      font-size: 8px;
    }
    .top-five-order {
      font-size: 10px;
      font-weight: 500;
    }
  }

  .video-container {
    .yt-iframe-box {
      .i-frame-container {
        .i-frame-play-icon {
          width: 35px;
          height: 35px;
          font-size: 20px;
        }
      }
    }
  }

  .release-caption,
  .fu-video-caption,
  .fu-blog-caption {
    .release-name,
    .fu-release-video-name,
    .fu-blog-title {
      -webkit-line-clamp: 1;
      font-size: 14px;
    }
    .release-artist,
    .fu-channel-video-name {
      font-size: 12px;
    }
  }

  .artist-box,
  .label-box {
    width: 136px;
    margin: auto;
    &:hover {
      .artist-img-container,
      .label-img-container {
        border-color: $fuYellowColor;
        .verification-circle {
          border-color: $fuYellowColor;
        }
      }
    }
    .artist-img-container,
    .label-img-container {
      & {
        border: 1px solid $fuWhiteColor;
      }
      .artist-img,
      .label-img {
        width: 136px;
        height: 136px;
      }
      .verification-circle {
        & {
          width: 19px;
          height: 19px;
          border: 1px solid $fuWhiteColor;
        }
        .futurising-icon {
          width: 10px;
          height: 10px;
        }
      }
    }

    .artist-name-container,
    .label-name-container {
      .artist-name,
      .label-name {
        font-size: 14px;
      }
    }
  }

  .team-member-box {
    .img-container {
      & {
        border: 2px solid $fuWhiteColor;
      }
      .img {
        width: 150px;
        height: 150px;
      }
    }
    .name-container {
      .name {
        font-size: 14px;
        font-weight: 600 !important;
      }
    }
    .job-title-container {
      .job-title {
        font-size: 12px;
      }
    }
  }

  .footer-copyrights {
    flex-direction: column;
    .copyrights-text {
      font-size: 15px !important;
      margin-bottom: 8px;
    }
    .author-text {
      & {
        font-size: 15px !important;
      }
      .dash {
        display: none;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 300px;
    }
  }

  .page-heading-title {
    font-size: 3.5rem;
  }

  .page-heading-long-title {
    font-size: 3rem;
  }

  .privacy-policies-description {
    & {
      font-size: 14px;
      font-weight: 500;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h5 {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h6 {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    a {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .fu-alert-yellow {
    font-weight: 400;
    font-size: 14px;
    padding: 4px 10px !important;
  }
}
// SM
@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .footer-copyrights {
    flex-direction: column;
    .copyrights-text {
      font-size: 15px !important;
      margin-bottom: 8px;
    }
    .author-text {
      & {
        font-size: 15px !important;
      }
      .dash {
        display: none !important;
      }
    }
  }
  [data-title]:after {
    font-size: 10px;
    padding: 2px 2px;
  }
  .search-modal {
    .modal-box {
      width: 80%;
      .search-modal-title {
        font-size: 18px;
      }
    }
  }
  .search-modal {
    .modal-box {
      // width: 90%;
      .search-modal-title {
        font-size: 18px;
      }
    }
  }
  .fm-about-description {
    font-size: 14px;
  }
  .accordion {
    .accordion-item {
      .accordion-header {
        .accordion-button {
          font-weight: 400 !important;
        }
      }
    }
  }
  .swal2-popup {
    & {
      width: 380px;
    }
    .swal2-icon {
      & {
        margin-top: 2.5rem;
        width: 3.7rem;
        height: 3.7rem;
      }
      .swal2-icon-content {
        font-size: 3rem;
      }
    }
    .swal2-title {
      font-size: 1.6rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 350px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .item-msg {
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }

  .release-container {
    width: fit-content;
    margin: auto;
    .release-img-container {
      width: 237px;
      height: 237px;
      .listen-now-layer {
        padding: 12px 0;
        .listen-text {
          font-size: 14px;
        }
      }
    }
    .release-caption {
      width: 237px;
    }
  }
  .top-five-circle,
  .pre-order-circle {
    width: 18px;
    height: 18px;
    font-size: 7px;
    .top-five-order {
      font-size: 9px;
      font-weight: 500;
    }
  }

  .details-top-five-circle,
  .details-pre-order-circle {
    & {
      width: 16px;
      height: 16px;
      font-size: 8px;
    }
    .top-five-order {
      font-size: 10px;
      font-weight: 500;
    }
  }

  .blog-box {
    .blog-img-container {
      width: 500px;
      height: 185.06px;
    }
  }
  .release-caption,
  .fu-video-caption,
  .fu-blog-caption {
    .release-name,
    .fu-release-video-name,
    .fu-blog-title {
      -webkit-line-clamp: 1;
    }
  }

  .artist-box,
  .label-box {
    width: 165px;
    margin: auto;
    &:hover {
      .artist-img-container,
      .label-img-container {
        border-color: $fuYellowColor;
        .verification-circle {
          border-color: $fuYellowColor;
        }
      }
    }
    .artist-img-container,
    .label-img-container {
      & {
        border: 2px solid $fuWhiteColor;
      }
      .artist-img,
      .label-img {
        width: 165px;
        height: 165px;
      }
      .verification-circle {
        & {
          width: 18px;
          height: 18px;
        }
        .futurising-icon {
          width: 10px;
          height: 10px;
        }
      }
    }

    .artist-name-container,
    .label-name-container {
      .artist-name,
      .label-name {
        font-size: 14px;
      }
    }
  }

  .team-member-box {
    .img-container {
      & {
        border: 2px solid $fuWhiteColor;
      }
      .img {
        width: 150px;
        height: 150px;
      }
    }
    .name-container {
      .name {
        font-size: 14px;
        font-weight: 600 !important;
      }
    }
    .job-title-container {
      .job-title {
        font-size: 12px;
      }
    }
  }

  .footer-copyrights {
    display: flex;
    align-items: center;
    .copyrights-text {
      display: inline-flex;
      font-size: 15px !important;
    }
    .author-text {
      & {
        font-size: 15px !important;
        display: inline-flex;
        align-items: center;
      }
      .dash {
        display: inline-flex;
        width: 10px;
        height: 3px;
        border-radius: 8px;
        background-color: $fuYellowColor;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 350px;
    }
  }

  .page-heading-title {
    font-size: 4rem;
  }
  .page-heading-long-title {
    font-size: 3.2rem;
  }

  .privacy-policies-description {
    & {
      font-size: 14px;
      font-weight: 500;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h5 {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    h6 {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    a {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
// MD
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .search-modal {
    .modal-box {
      .search-modal-title {
        font-size: 18px;
      }
    }
  }
  .fm-about-description {
    font-size: 14px;
  }
  .swal2-popup {
    & {
      width: 410px;
    }
    .swal2-icon {
      & {
        margin-top: 2.5rem;
        width: 4rem;
        height: 4rem;
      }
      .swal2-icon-content {
        font-size: 3.1rem;
      }
    }
    .swal2-title {
      font-size: 1.7rem;
      padding-top: 12px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .release-container {
    .release-img-container {
      width: 311px;
      height: 311px;
      .listen-now-layer {
        padding: 15px 0;
        .listen-text {
          font-size: 14px;
        }
      }
    }
  }

  .blog-box {
    .blog-img-container {
      width: 680px;
      height: 251.7px;
    }
  }
  .artist-box,
  .label-box {
    width: 165px;
    margin: auto;
    .artist-img-container,
    .label-img-container {
      .artist-img,
      .label-img {
        width: 165px;
        height: 165px;
      }
      .verification-circle {
        & {
          width: 18px;
          height: 18px;
        }
        .futurising-icon {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .team-member-box {
    .img-container {
      .img {
        width: 160px;
        height: 160px;
      }
    }
    .name-container {
      .name {
        font-size: 14px;
        font-weight: 600 !important;
      }
    }
    .job-title-container {
      .job-title {
        font-size: 12px;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 400px;
    }
  }

  .page-heading-title {
    font-size: 4.5rem;
  }

  .page-heading-long-title {
    font-size: 3.8rem;
  }
}
// LG
@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .fu-col-lg-20 {
    flex: 0 0 auto !important;
    width: 20% !important;
  }
  .swal2-popup {
    & {
      width: 450px;
    }
    .swal2-icon {
      & {
        margin-top: 2.5rem;
        width: 4.2rem;
        height: 4.2rem;
      }
      .swal2-icon-content {
        font-size: 3.1rem;
      }
    }
    .swal2-title {
      font-size: 1.7rem;
      padding-top: 12px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .release-container {
    .release-img-container {
      width: 273.33px;
      height: 273.33px;
      .listen-now-layer {
        padding: 18px 0;
        .listen-text {
          font-size: 14px;
        }
      }
    }
  }

  .blog-box {
    .blog-img-container {
      width: 447px;
      height: 165.45px;
    }
  }

  .artist-box,
  .label-box {
    width: 170px;
    margin: auto;
    .artist-img-container,
    .label-img-container {
      .artist-img,
      .label-img {
        width: 170px;
        height: 170px;
      }
      .verification-circle {
        & {
          width: 18px;
          height: 18px;
        }
        .futurising-icon {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .team-member-box {
    .img-container {
      .img {
        width: 180px;
        height: 180px;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 430px;
    }
  }

  .page-heading-title {
    font-size: 4.5rem;
  }

  .page-heading-long-title {
    font-size: 4.2rem;
  }
}
// XL
@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .fu-col-xl-20 {
    flex: 0 0 auto !important;
    width: 20% !important;
  }
  .release-container {
    .release-img-container {
      width: 333.333px;
      height: 333.333px;
      .listen-now-layer {
        .listen-text {
          font-size: 14px;
        }
      }
    }
  }
  .blog-box {
    .blog-img-container {
      width: 521px;
      height: 192.84px;
    }
  }
  .team-member-box {
    .img-container {
      .img {
        width: 170px;
        height: 170px;
      }
    }
  }
  .page-heading-long-title {
    font-size: 4.5rem;
  }
}

// XXL
@media screen and (min-width: 1400px) {
  .fu-col-xxl-20 {
    flex: 0 0 auto !important;
    width: 20% !important;
  }
}

// ------------------------------------------------

@media screen and (min-height: 0) and (max-height: 735px) {
  .virtual-nav-width {
    width: 80px !important;
    min-height: 100% !important;
    transition: all 0.2s;
  }
  .main-navbar {
    width: 80px !important;
    .icon-width {
      width: 80px !important;
    }
    .nav-link-icon-container {
      width: 80px !important;
    }
    &:hover {
      width: calc(22rem + 10px) !important;
    }
    .navbar-links-list {
      padding: 1.5rem 0;
    }
  }
  .mobile-nav {
    .mobile-side-nav {
      .navbar-links-list {
        padding: 1.5rem 0;
      }
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1420px) {
  .virtual-nav-width {
    width: 62px !important;
  }
  .main-navbar {
    width: 62px !important;
    .icon-width {
      width: 62px !important;
    }
    .nav-link-icon-container {
      width: 62px !important;
    }
  }
}
