@import "../../styles/index.scss";

.social_box {
  & {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .social_icon_container {
    & {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      transition: all 0.3s;
    }
    .social_icon {
      fill: $fuLightGrayColor;
      transition: all 0.3s;
    }
    &:hover {
      .social_icon {
        fill: $fuYellowColor;
      }
    }
  }
}

// VS
@media screen and (min-width: 0) and (max-width: 310px) {
  .social_box {
    .social_icon_container {
      & {
        width: 18px;
        height: 18px;
        margin: 0 4px 3px;
      }
      .social_icon {
        fill: $fuLightGrayColor;
        transition: all 0.3s;
      }
    }
  }
}
// XXXS
@media screen and (min-width: 310px) and (max-width: 399.9px) {
  .social_box {
    .social_icon_container {
      & {
        width: 18px;
        height: 18px;
        margin: 0 4px;
      }
      .social_icon {
        fill: $fuLightGrayColor;
        transition: all 0.3s;
      }
    }
  }
}
// XXS
@media screen and (min-width: 400px) and (max-width: 479.9px) {
  .social_box {
    .social_icon_container {
      & {
        width: 18px;
        height: 18px;
        margin: 0 4px;
      }
      .social_icon {
        fill: $fuLightGrayColor;
        transition: all 0.3s;
      }
    }
  }
}
// XS
@media screen and (min-width: 480px) and (max-width: 575.9px) {
  .social_box {
    .social_icon_container {
      & {
        width: 18px;
        height: 18px;
        margin: 0 4px;
      }
      .social_icon {
        fill: $fuLightGrayColor;
        transition: all 0.3s;
      }
    }
  }
}
// SM
@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .social_box {
    .social_icon_container {
      & {
        width: 18px;
        height: 18px;
        margin: 0 4px;
      }
      .social_icon {
        fill: $fuLightGrayColor;
        transition: all 0.3s;
      }
    }
  }
}
// MD
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .social_box {
    .social_icon_container {
      & {
        width: 18px;
        height: 18px;
        margin: 0 4px;
      }
    }
  }
}
