@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./colors";
@import "./mixins";
@import "./common";

*::selection {
  background-color: $fuYellowColor;
  color: $fuMidGrayColor;
}

::-webkit-scrollbar {
  width: 18px;
}
::-moz-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: $fuBlackColor;
}
::-moz-scrollbar-track {
  background-color: $fuBlackColor;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #fdb915;
  transition: all 0.2s;
  &:hover {
    background-color: #d19914;
  }
}
::-moz-scrollbar-thumb {
  border-radius: 8px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #fdb915;
  transition: all 0.2s;
  &:hover {
    background-color: #d19914;
  }
}

::-webkit-scrollbar-button:single-button {
  background-color: $fuBlackColor;
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}
::-moz-scrollbar-button:single-button {
  background-color: $fuBlackColor;
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 14px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(253, 185, 21)' class='bi bi-caret-up-fill' viewBox='2 2 14 14'> <path d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/></svg>");
}
::-moz-scrollbar-button:single-button:vertical:decrement {
  height: 14px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(253, 185, 21)' class='bi bi-caret-up-fill' viewBox='2 2 14 14'> <path d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 14px;
  width: 16px;
  background-position: center 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(253, 185, 21)' class='bi bi-caret-down-fill' viewBox='2 2 14 14'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
}
::-moz-scrollbar-button:single-button:vertical:increment {
  height: 14px;
  width: 16px;
  background-position: center 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(253, 185, 21)' class='bi bi-caret-down-fill' viewBox='2 2 14 14'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
}

:root {
  --toastify-icon-color-success: #fdb915;
}

body {
  background-color: $fuDarkGrayColor !important;
  font-family: "Poppins", sans-serif;
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: unset !important;
}

.nav-open {
  overflow: hidden !important;
  .outlet {
    transform: translateX(calc(22rem - 70px));
  }
}

.layout-container {
  display: flex;
}

.outlet {
  transform: translateX(0);
  transition: transform 0.3s;
}

.main-navbar {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    display: none;
  }
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-moz-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $fuBlackColor;
  }

  &::-moz-scrollbar-track {
    background-color: $fuBlackColor;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;

    border: 1px solid transparent;
    background-clip: content-box;
    background-color: #fdb915;
    transition: all 0.2s;
    &:hover {
      background-color: #d19914;
    }
  }

  &::-moz-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: #fdb915;
    transition: all 0.2s;
    &:hover {
      background-color: #d19914;
    }
  }
}

.virtual-nav-width {
  width: 70px !important;
  min-height: 100% !important;
  transition: all 0.2s;
}

.nav-streaming {
  min-height: 128px;
}

.regular-opacity-transition {
  transition: opacity 0.2s !important;
}

.fu-transition {
  transition: all 0.3s !important;
}

a[disabled] {
  pointer-events: none !important;
  cursor: default !important;
  color: $fuLightGrayColor !important;
  // opacity: 0.5 !important;
}

.fu-btn-gray {
  background-color: $fuTransparentColor !important;
  border: 1px solid $fuLightGrayColor !important;
  color: $fuLightGrayColor !important;
  border-radius: 50px;
  transition: all 0.4s ease-out;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .share-icon-svg {
    // fill: none;
    stroke: $fuLightGrayColor;
    fill: $fuLightGrayColor;
    transition: fill 0.3s ease-out, stroke 0.3s ease-out;
  }
  .btn-bg {
    display: inline-flex;
    position: absolute;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: rotate(10deg) translateX(100%);
    background-color: $fuYellowColor;
    border-radius: 50px;
    transition: all 0.5s ease-out;
  }
  &:hover {
    border-color: $fuYellowColor !important;
    color: $fuBlackColor !important;
    transition: all 0.3s ease-out;

    .btn-bg {
      width: 250%;
      height: 250%;
      transform: rotate(10deg) translateX(0);
      transition: all 0.3s ease-out;
    }
    .share-icon-svg {
      fill: $fuBlackColor;
      stroke: $fuBlackColor;
      transition: all 0.15s ease-in;
    }
  }
}

.fu-btn-white {
  background-color: $fuTransparentColor !important;
  border: 1px solid $fuWhiteColor !important;
  color: $fuWhiteColor !important;
  border-radius: 50px;
  transition: all 0.4s ease-out;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .share-icon-svg {
    // fill: none;
    stroke: $fuLightGrayColor;
    fill: $fuLightGrayColor;
    transition: fill 0.3s ease-out, stroke 0.3s ease-out;
  }
  .btn-bg {
    position: absolute;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: rotate(10deg) translateX(100%);
    background-color: $fuYellowColor;
    border-radius: 50px;
    transition: all 0.4s ease-out;
  }
  &:hover {
    border-color: $fuYellowColor !important;
    color: $fuBlackColor !important;
    transition: all 0.3s ease-out;

    .btn-bg {
      width: 250%;
      height: 250%;
      transform: rotate(10deg) translateX(0);
      transition: all 0.3s ease-out;
    }
    .share-icon-svg {
      fill: $fuBlackColor;
      stroke: $fuBlackColor;
      transition: all 0.15s ease-in;
    }
  }
}

.fu-btn {
  padding: 8px 16px;
  line-height: 1.2;
  text-decoration: none;
  display: inline-flex;
}

.fu-btn-sm {
  padding: 5px 14px;
  font-size: 14px;
  line-height: 1.2 !important;
  text-decoration: none;
  display: inline-flex;
}

.link-dashed {
  color: $fuYellowColor;
  text-decoration: underline dotted;
}

@keyframes fill-btn {
  0% {
    background: linear-gradient(135deg, $fuTransparentColor 100%, $fuYellowColor 100%);
  }
  100% {
    background: linear-gradient(135deg, $fuTransparentColor 50%, $fuYellowColor 50%);
  }
}

.carousel {
  position: static !important;
  .carousel-control-prev,
  .carousel-control-next {
    position: relative;
    display: inline-flex;
    width: 50px;
    left: 50%;
    margin: 1.5rem 0;
    transform: translateX(calc(-100% - 5px));
    color: $fuWhiteColor;
    opacity: 0.2 !important;
    transition: all 0.3s;
    &:hover {
      opacity: 1 !important;
      color: $fuYellowColor;
    }
  }
  .carousel-control-prev {
    margin-right: 5px;
  }
  .carousel-control-next {
    margin-left: 5px;
  }
}
.carousel-inner {
  position: static !important;
}
.carousel-item {
  position: static !important;
}

#homeCarousel {
  .carousel-inner {
    .carousel-item {
      .fu-carousel-slide {
        animation-name: fade-out;
        animation-duration: 500ms;
        animation-delay: 5500ms;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
      }
    }

    .active {
      .fu-carousel-slide {
        .fu-carousel-caption {
          .fu-slide-caption-heading {
            animation-name: slide-in;
            animation-duration: 500ms;
            animation-delay: 200ms;
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
          }
          .fu-slide-caption-descriprion {
            animation-name: slide-in;
            animation-duration: 500ms;
            animation-fill-mode: both;
            animation-delay: 400ms;
            animation-timing-function: ease-in-out;
          }
        }
        .fu-btn {
          animation-name: slide-in;
          animation-duration: 500ms;
          animation-fill-mode: both;
          animation-delay: 800ms;
          animation-timing-function: ease-in-out;
        }
        .fu-carousel-img-size {
          animation-name: img-fade-in;
          animation-duration: 1500ms;
          animation-fill-mode: both;
        }
      }
      .fu-bg-blur-fade-out {
        animation-name: bg-blur-fade-out;
        animation-duration: 500ms;
        animation-delay: 5500ms;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
      }
      .fu-bg-blur-fade-in {
        animation-name: bg-blur-fade-in;
        animation-duration: 1000ms;
        animation-delay: 500ms;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
      }
    }
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes img-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-in {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes bg-blur-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes bg-blur-fade-out {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}

.page-heading {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  background-color: $fuLightBlackColor;
  margin-bottom: -100px;
  filter: grayscale(100%);
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(360deg, $fuDarkGrayColor 9%, rgba($color: $fuDarkGrayColor, $alpha: 0) 100%);
    transform: translateY(1px);
  }
  .heading-img-container {
    transition: all 0.2s;
    height: 510px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.page-heading-title,
.page-heading-long-title {
  font-size: 5rem;
  color: $fuWhiteColor;
  font-weight: 800;
}

.video-container,
.release-container,
.new-blog-container {
  & {
    position: relative;
    border-radius: 4px !important;
    overflow: hidden;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(to top, rgba(20, 20, 20, 0.9), rgba(46, 46, 46, 0.2));
    opacity: 0.8;
    filter: blur(50px);
    z-index: 1;
  }
  .pre-order-label-container {
    width: 200px;
    height: 30px;
    background-color: $fuLightGrayColor;
    color: $fuDarkGrayColor;
    // box-shadow: 0 0 30px 1px rgba($color: $fuLightGrayColor, $alpha: 0.4);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    font-size: 14px;
    transform: rotate(-45deg) translateX(-60px) translateY(-15px);
    @extend .fu-fw-600;
  }
  .release-bg,
  .new-blog-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    filter: blur(40px);
    opacity: 0.8;
    transform: scale(1.2);
  }
  .release-img-container {
    width: 284.5px;
    height: 284.5px;
    border-radius: 4px;
    position: relative;
    overflow: hidden !important;
    z-index: 10;
    .listen-now-layer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 18px 0;
      opacity: 0;
      background-color: $fuYellowColor;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(100%);
      transition: all 0.3s;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .listen-text {
        // display: block;
        color: $fuLightBlackColor;
        font-size: 14px;
        letter-spacing: 3px;
        font-weight: 600;
      }
    }
  }
  &:hover .listen-now-layer {
    transform: translateY(0);
    opacity: 1;
  }
}
.release-container,
.new-blog-container {
  cursor: pointer;
}
.release-caption,
.fu-video-caption,
.fu-blog-caption {
  position: relative;
  z-index: 999;
  .release-name,
  .fu-release-video-name,
  .fu-blog-title {
    font-size: 16px;
    color: $fuWhiteColor !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-word-break: break-word;
    word-break: break-word;
  }
  .release-artist,
  .fu-channel-video-name {
    font-size: 14px;
    color: $fuOffWhiteColor;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.yt-iframe-box {
  & {
    position: relative;
    z-index: 3;
  }
  .i-frame-container {
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    .i-frame-release-poster {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      background-color: $fuBlackColor;
    }
    .i-frame {
      width: 100%;
      height: 100%;
    }
    .i-frame-play-icon {
      width: 50px;
      height: 50px;
      color: $fuWhiteColor;
      background-color: $fuMidGrayColor;
      position: relative;
      border-radius: 50%;
      border: 2px solid transparent;
      font-size: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      .play-icon {
        display: inline-flex;
      }
    }
    &:hover {
      .i-frame-play-icon {
        color: $fuYellowColor;
      }
    }
  }
}

.blog-box {
  & {
    position: relative;
    z-index: 3;
  }
  .blog-img-container {
    width: 611px;
    height: 226.16px;
    border-radius: 4px;
    overflow: hidden;
  }
  .fu-blog-title {
    font-weight: 300;
  }
  .fu-blog-read {
    font-size: 14px;
    font-weight: 400;
    color: $fuYellowColor;
    display: flex;
    align-items: center;
  }
}

.artist-box,
.label-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 190px;
  margin: auto;
  &:hover {
    .artist-name-container,
    .label-name-container {
      .artist-name,
      .label-name {
        color: $fuYellowColor;
      }
    }
    .artist-img-container,
    .label-img-container {
      border-color: $fuYellowColor;
      .verification-circle {
        border-color: $fuYellowColor;
      }
    }
  }
  cursor: pointer;
  .artist-img-container,
  .label-img-container {
    & {
      border: 2px solid $fuWhiteColor;
      border-radius: 50%;
      position: relative;
      transition: all 0.3s ease;
    }
    .artist-img,
    .label-img {
      width: 190px;
      height: 190px;
      border-radius: 50%;
      overflow: hidden;
    }
    .verification-circle {
      & {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: $fuDarkGrayColor;
        border-width: 0;
        border: 2px solid $fuWhiteColor;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        transition: all 0.3s ease;
      }
      .futurising-icon {
        width: 12px;
        height: 12px;
      }
      .lazy-load-image-background {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }
  .artist-name-container,
  .label-name-container {
    .artist-name,
    .label-name {
      font-size: 14px;
      color: $fuWhiteColor;
      transition: all 0.3s ease;
      width: fit-content !important;
      @extend .line-clamp-1;
    }
  }
}

.share-box-container {
  .share-icons-box:not(.show) {
    display: flex;
    width: 0;
    overflow: hidden;
  }
  .show {
    display: flex;
    width: 100%;
  }
}

.mouse-pointer {
  cursor: pointer;
}

.fu-bg-transparent {
  background-color: $fuTransparentColor !important;
}

.fu-bg-dark-gray {
  background-color: $fuDarkGrayColor !important;
}

.fu-bg-black {
  background-color: $fuBlackColor !important;
}

.fu-bg-white {
  background-color: $fuWhiteColor !important;
}

.fu-bg-yellow {
  background-color: $fuYellowColor;
}

.fu-text-light-gray {
  color: $fuLightGrayColor !important;
}

.fu-text-white {
  color: $fuWhiteColor !important;
}

.fu-text-yellow {
  color: $fuYellowColor !important;
}

.fu-fw-800 {
  font-weight: 800 !important;
}

.fu-fw-700 {
  font-weight: 700 !important;
}

.fu-fw-600 {
  font-weight: 600 !important;
}

.fu-fw-400 {
  font-weight: 400 !important;
}

.fu-fw-300 {
  font-weight: 300 !important;
}

.fu-fw-200 {
  font-weight: 200 !important;
}

.fu-text-justify {
  text-align: justify !important;
}
.fu-text-1-rem {
  font-size: 1rem !important;
  transition: all 0.2s;
}

.fu-text-2-rem {
  font-size: 2rem !important;
  transition: all 0.2s;
}

.fu-text-3-rem {
  font-size: 3rem !important;
  transition: all 0.2s;
}

.fu-text-4-rem {
  font-size: 4rem !important;
  transition: all 0.2s;
}

.fu-rounded {
  border-radius: 4px;
  overflow: hidden;
}

.w-max {
  width: max-content !important;
}

.w-fit {
  width: fit-content !important;
}

.fu-input {
  box-shadow: none !important;
  background-color: $fuLightGrayColor;
  transition: all 0.3s;
  border-color: $fuDarkGrayColor;
  &:focus {
    background-color: $fuWhiteColor;
    border-color: $fuYellowColor !important;
  }
}

.fm-about-description {
  font-size: 16px;
  color: $fuLightGrayColor;
}

.team-member-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .img-container {
    & {
      border: 2px solid $fuWhiteColor;
      border-radius: 50%;
      position: relative;
    }
    .img {
      width: 190px;
      height: 190px;
      border-radius: 50%;
      overflow: hidden;
      // filter: grayscale(100%);
    }
  }
  .name-container {
    // width: 100%;
    .name {
      font-size: 16px;
      color: $fuWhiteColor;
      @extend .fu-fw-700;
    }
  }
  .job-title-container {
    // width: 100%;
    .job-title {
      font-size: 14px;
      color: $fuLightGrayColor;
      @extend .fu-fw-300;
    }
  }
}

.fu-breadcrumb {
  // background-color: $fuBlackColor;
  & {
    background-color: $fuBlackColor;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: "/";
    position: relative !important;
    color: $fuLightGrayColor !important;
    top: 0;
    line-height: 1;
    overflow: hidden !important;
    transform: translateY(-1px);
  }

  .fu-breadcrumb-item {
    display: inline-flex;
    align-items: center;
    .fu-link-text {
      font-size: 12px;
      text-decoration: none;
      color: $fuLightGrayColor;
      transition: all 0.3s;
      line-height: 1 !important;
      display: inline-flex;
    }
    &:hover {
      .fu-link-text {
        color: $fuYellowColor;
      }
    }
  }

  .fu-breadcrumb-item-active {
    .fu-link-text {
      color: $fuYellowColor;
      @extend .fu-fw-600;
    }
  }
}

.modal-backdrop {
  background-color: $fuTransparentColor !important;
}

.search-modal {
  .modal {
    z-index: 9999999 !important;
    inset: 0 !important;
  }
  .modal-dialog-container {
    height: 100%;
    overflow: hidden;
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: none !important;
  }
  .modal-box {
    width: 75%;
    position: relative;
    .close-btn-container {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      transform: translateY(-100%);
      .fu-btn-close {
        border: none;
        background-color: $fuTransparentColor;
        color: $fuLightGrayColor;
        transition: all 0.3s;
        font-size: 20px;
        &:hover {
          color: $fuYellowColor;
        }
      }
    }
    .search-modal-title {
      font-size: 20px;
    }
  }
  .search-modal-backdrop {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to bottom, rgba($color: $fuBlackColor, $alpha: 0.5), rgba($color: $fuBlackColor, $alpha: 0));
    filter: blur(10px);
    backdrop-filter: blur(0px);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
  }
  .show {
    .search-modal-backdrop {
      opacity: 1;
      transition: opacity 0.6s ease-in-out;
    }
  }
}

.fu-outline-yellow {
  border: 1px solid $fuYellowColor;
}

.fu-shadow-light {
  box-shadow: 0px 0px 20px 3px rgba($color: $fuLightGrayColor, $alpha: 0.2);
}
.swal2-popup {
  background-color: $fuDarkGrayColor;
  border: 2px solid rgba($color: $fuYellowColor, $alpha: 0.4) !important;
}

.swal2-container {
  z-index: 9999999 !important;
}

.swal2-icon.swal2-warning,
.swal2-icon.swal2-question {
  border-color: rgba($color: $fuYellowColor, $alpha: 0.4) !important;
  color: $fuYellowColor !important;
}

.swal2-container.swal2-backdrop-show,
div:where(.swal2-container).swal2-backdrop-show {
  background: rgba($color: $fuBlackColor, $alpha: 0.8);
}
.popup-btn {
  background-color: $fuTransparentColor !important;
  border: 1px solid $fuLightGrayColor !important;
  color: $fuLightGrayColor !important;
  border-radius: 50px !important;
  transition: all 0.4s ease-out;
  overflow: hidden !important;
  position: relative !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  padding: 7px 15px;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 1 !important;
  &::after {
    content: "";
    position: absolute;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: rotate(10deg) translateX(100%);
    background-color: $fuYellowColor;
    border-radius: 50px;
    transition: all 0.5s ease-out;
  }
  &:hover {
    border-color: $fuYellowColor !important;
    color: $fuBlackColor !important;
    transition: all 0.3s ease-out;
    &::after {
      width: 250%;
      height: 250%;
      transform: rotate(10deg) translateX(0);
      transition: all 0.3s ease-out;
    }
  }
}

.privacy-policies-description {
  & {
    color: $fuLightGrayColor;
    font-size: 16px;
    font-weight: 500;
  }

  h2 {
    font-size: 20px;
    color: $fuYellowColor;
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  h3 {
    font-size: 18px;
    color: $fuWhiteColor;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  h4 {
    font-size: 16px;
    color: $fuWhiteColor;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  h5 {
    font-size: 14px;
    color: $fuWhiteColor;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  h6 {
    font-size: 12px;
    color: $fuWhiteColor;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  p {
    color: $fuLightGrayColor;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }
  a {
    text-decoration: underline;
    color: $fuLightGrayColor;
    transition: all 0.3s;
    font-size: 16px;
    font-weight: 500;
    &:hover {
      color: $fuYellowColor;
    }
  }
  h2 + br,
  h3 + br,
  h4 + br,
  h5 + br,
  h6 + br,
  p + br {
    content: "";
  }
}

.lazy-load-image-background {
  display: block !important;
}

.author-link {
  display: inline-flex;
  text-decoration: underline;
  color: $fuYellowColor;
}
